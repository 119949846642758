import request from '@/utils/request'


// 查询用户封禁记录列表
export function listUser(query) {
  return request({
    url: '/biz/userBan/list',
    method: 'get',
    params: query
  })
}

// 查询用户封禁记录分页
export function pageUser(query) {
  return request({
    url: '/biz/userBan/getUserBanPage',
    method: 'get',
    params: query
  })
}

// 查询用户封禁记录详细
export function getUser(data) {
  return request({
    url: '/biz/userBan/detail',
    method: 'get',
    params: data
  })
}

// 新增用户封禁记录
export function addUser(data) {
  return request({
    url: '/biz/userBan/add',
    method: 'post',
    data: data
  })
}

// 修改用户封禁记录
export function updateUser(data) {
  return request({
    url: '/biz/userBan/edit',
    method: 'post',
    data: data
  })
}

// 删除用户封禁记录
export function delUser(data) {
  return request({
    url: '/biz/userBan/delete',
    method: 'post',
    data: data
  })
}
